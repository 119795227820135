import {
  Box,
  Container,
  Text,
  Image,
  useMediaQuery,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Input,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { Logo } from '../../components/Footer/styleComponent/Logo';
import style from '../../assets/style.json';
import bg from '../../assets/bg.png';
import { NewsVector } from '../../assets/NewsVector';
import newsHeader from '../../assets/newsHeader.png';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import UserService from '../../services/user-service';
import CompanyService from '../../services/company-service';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../locales/LocaleContext';
// import QuestionList from './components/question-list';

const steps = [
  { title: 'onboarding.personalInformation' },
  { title: 'onboarding.toc' },
  { title: 'onboarding.form' },
];

type OnboardingProps = {
  userInfo: any;
};

function Onboarding({ userInfo }: OnboardingProps) {
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale, setLocale } = useContext(LanguageContext);
  const { activeStep, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const toggleLanguage = (newLanguage: string) => {
    localStorage.setItem('locale', newLanguage);
    setLocale(newLanguage);
  };

  const [personalInformation, setPersonalInformation] = useState<{
    name: string;
    roleIds: string[];
  }>({
    name: '',
    roleIds: [],
  });
  const [onboarding, setOnboarding] = useState<any>(null);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [onboardingAnswers, setOnboardingAnswers] = useState<any>({});

  useEffect(() => {
    async function loadOnboarding() {
      const res = await CompanyService.getOnboardingQuestionnaire();
      setOnboarding(res);
      toggleLanguage(res.lang ?? 'he');
    }
    loadOnboarding();
  }, []);

  const onSubmit = async () => {
    await UserService.onFinishOnboarding(
      Object.entries(onboardingAnswers).map(([questionId, answers]) => ({
        questionId,
        answers,
      })),
      personalInformation.name,
      personalInformation.roleIds,
    );
    navigate('/');
  };

  if (!onboarding) return null;
  return (
    <>
      <Box position="absolute" width={'100%'} height="16px" bg="#3FB2D2"></Box>
      <Container
        backgroundColor={false ? '' : '#20273d'}
        backgroundPosition="center"
        backgroundImage={bg}
        width={'100%'}
        height={'400px'}
        maxW={'100%'}
        position="absolute"
        display="flex"
        justifyContent={'center'}
      >
        <Box marginTop={'60px'}>
          <Logo isShowStand={false} isDark={false} />
        </Box>
      </Container>
      <Container
        position={'absolute'}
        top="200px"
        maxW="full"
        minH="100vh"
        padding={isPc ? 'unset' : 0}
        bg="#f4fbff"
        centerContent
      >
        <Box
          position={'relative'}
          width="full"
          rounded={'10px'}
          maxW={style['pc-view']}
          bg={isPc ? 'white' : '#f4fbff'}
          minHeight={'300px'}
          boxShadow={isPc ? '0px 0px 25px rgba(62, 62, 62, 0.08)' : 'unset'}
          borderTop={isPc ? '9px solid #20273D' : 'unset'}
          display={'flex'}
          flexDirection="column"
          paddingBlock={'20px'}
          paddingInline="30px"
        >
          {isPc && (
            <>
              <Box position={'absolute'} left="0" top="0">
                <NewsVector />
              </Box>

              <Image
                src={newsHeader}
                position="absolute"
                left={'34px'}
                top={0}
              />
            </>
          )}

          <Text
            mb={16}
            mt={16}
            fontWeight="bold"
            fontSize={30}
            style={{ textAlign: 'center' }}
          >
            {t('onboarding.header')}
          </Text>

          <Box>
            <Stepper
              sx={{
                marginInline: 'auto',
              }}
              index={activeStep}
            >
              {steps.map((step, index) => (
                <Step>
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    alignItems="center"
                    onClick={() => {
                      if (activeStep >= index) setActiveStep(index);
                    }}
                    key={index}
                    opacity={activeStep >= index ? 1 : 0.8}
                  >
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{t(step.title)}</StepTitle>
                    </Box>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Box>
                <Box>
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('onboarding.name')}
                  </Text>
                  <Input
                    placeholder={t('onboarding.name')}
                    borderColor="#E2E2E2"
                    bg={'white'}
                    rounded={'10px'}
                    fontSize="16px"
                    fontWeight={400}
                    paddingInline="12px"
                    paddingBlock="30px"
                    value={personalInformation.name}
                    onChange={(e) => {
                      setPersonalInformation({
                        ...personalInformation,
                        name: e.target.value,
                      });
                    }}
                    maxLength={50}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={4}>
                  <Text
                    marginBottom={'5px'}
                    fontSize={'14px'}
                    color="#aaa"
                    fontWeight="14px"
                  >
                    {t('onboarding.roles')}
                  </Text>
                  {!!onboarding.roles.length && (
                    <Menu closeOnSelect={false}>
                      <MenuButton
                        as={Button}
                        bg="#3FB2D2"
                        color="#fff"
                        rounded={'100px'}
                        lineHeight="42px"
                        minWidth={'112px'}
                      >
                        {t('onboarding.roles')}
                      </MenuButton>
                      <MenuList overflowY="auto" maxH={'300px'}>
                        <Text px={3} fontWeight="bold" mb={2}>
                          {t('modifyCompany.roles')}
                        </Text>
                        {(onboarding.roles ?? []).map((r: any, i: number) => (
                          <MenuItem key={'role-' + i + '-' + r.id}>
                            <Checkbox
                              mr={2}
                              colorScheme="white"
                              borderColor="#3FB2D2"
                              iconColor="#3FB2D2"
                              iconSize={'17px'}
                              size="lg"
                              id={r.id}
                              name={r.id}
                              value={
                                personalInformation.roleIds.includes(r.id)
                                  ? 'checked'
                                  : 'unchecked'
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setPersonalInformation({
                                    ...personalInformation,
                                    roleIds: [
                                      ...personalInformation.roleIds,
                                      r.id,
                                    ],
                                  });
                                } else {
                                  setPersonalInformation({
                                    ...personalInformation,
                                    roleIds: personalInformation.roleIds.filter(
                                      (id) => id !== r.id,
                                    ),
                                  });
                                }
                              }}
                            >
                              {r.name}
                            </Checkbox>
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  )}

                  <Button
                    isDisabled={
                      !personalInformation.name.trim() ||
                      (!personalInformation.roleIds.length &&
                        onboarding.roles.length)
                    }
                    onClick={goToNext}
                  >
                    {t('onboarding.next')}
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <pre dir="rtl" style={{ whiteSpace: 'pre-wrap' }}>
                  {t('termsOfUse.contents')}
                </pre>
                {userInfo && (
                  <FormControl
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                    mt="2rem"
                  >
                    <FormLabel
                      dir="rtl"
                      display="flex"
                      alignItems="center"
                      gap="5px"
                    >
                      <Checkbox
                        size="lg"
                        colorScheme="white"
                        borderColor="#3FB2D2"
                        iconColor="#3FB2D2"
                        iconSize={'17px'}
                        isChecked={isAcceptTerms}
                        onChange={() => setIsAcceptTerms((prev) => !prev)}
                      />
                      {t('termsOfUse.readAndApprove')}
                    </FormLabel>
                    <Button isDisabled={!isAcceptTerms} onClick={goToNext}>
                      {t('onboarding.next')}
                    </Button>
                  </FormControl>
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Box display="flex" flexDirection="column" gap={4}>
                {/* <QuestionList
                  onboardingAnswers={onboardingAnswers}
                  setOnboardingAnswers={setOnboardingAnswers}
                  questions={onboarding.questions}
                /> */}
                <Button
                  isDisabled={(onboarding?.questions ?? []).some(
                    (q: any) =>
                      q.isRequired &&
                      (!onboardingAnswers[q.id] ||
                        !onboardingAnswers[q.id].length),
                  )}
                  onClick={onSubmit}
                >
                  {t('onboarding.next')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Onboarding;
