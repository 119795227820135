import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/Footer/styleComponent/PrimaryButton';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import CompanyService from '../../../services/company-service';
import { ArrowDownIcon } from '../../../assets/ArrowDownIcon';

type OnboardingSettingsProps = {
  company: any;
  onboardingList: any[];
};

function OnboardingSettings({
  company,
  onboardingList,
}: OnboardingSettingsProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isPc] = useMediaQuery('(min-width: 1300px)');
  const [selectedOnboardingId, setSelectedOnboardingId] = useState('');
  const [selectedRoleIds, setSelectedRoleIds] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOnboardingId(company.onboardingQuestionnaireId ?? 0);
    console.log({ company });

    setSelectedRoleIds(company.onboardingIncludeRoleIds ?? []);
  }, [isOpen, company]);

  const setCompanyOnboardingQuestionnaire = async () => {
    if (!company) return;
    await CompanyService.setCompanyOnboardingQuestionnaire(
      company.id,
      selectedOnboardingId,
      selectedRoleIds,
    );
  };

  return (
    <>
      <Button
        color={'white'}
        minW={isPc ? '200px' : 'full'}
        height="60px"
        textAlign="center"
        border="3px solid #FFFFFF"
        background={'#3FB2D2'}
        fontSize="18px"
        fontWeight={700}
        rounded={'100px'}
        filter="drop-shadow(0px 4px 30px rgba(63, 178, 210, 0.44))"
        display="flex"
        gap="8px"
        alignItems="center"
        onClick={onOpen}
      >
        {t('adminOnboarding.settings')}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxW={'100%'}
          width={isPc ? '840px' : 'calc(100% - 20px)'}
        >
          <ModalHeader
            className="interval"
            display="flex"
            alignItems={'center'}
            justifyContent="center"
            textAlign={'center'}
            gap="30px"
            position={'relative'}
          >
            <Text color="#475154">{t('adminOnboarding.manual')}</Text>
          </ModalHeader>
          <ModalCloseButton
            position={'absolute'}
            top={isPc ? '-40px' : '5px'}
            color={isPc ? 'white' : 'unset'}
            border={isPc ? '1px solid white' : '1px solid rgb(26,32,44)'}
            rounded={'100px'}
            left={isPc ? '0' : '25px'}
            right="unset"
          />
          <ModalBody>
            <Box>
              <Text
                marginBottom={'5px'}
                fontSize={'14px'}
                color="#aaa"
                fontWeight="14px"
              >
                {t('adminOnboarding.onboardingQuestionnaire')}
              </Text>
              <Menu closeOnSelect={true}>
                <MenuButton
                  as={Box}
                  bg="white"
                  height={'54px'}
                  border="1px solid #e2e2e2"
                  rounded="10px"
                  width={'300px'}
                >
                  <Box
                    padding="12px 17px"
                    display="flex"
                    flexDirection={'row'}
                    alignItems="center"
                    width={'full'}
                    justifyContent="space-between"
                  >
                    <Box width={'calc(100% - 20px)'}>
                      <Text
                        textOverflow={'ellipsis'}
                        width={'100%'}
                        overflow={'hidden'}
                        whiteSpace="nowrap"
                      >
                        {onboardingList.find(
                          (o) => o.id === selectedOnboardingId,
                        )?.name ?? t('adminOnboarding.onboardingQuestionnaire')}
                      </Text>
                    </Box>
                    <Box flexShrink={0}>
                      <ArrowDownIcon />
                    </Box>
                  </Box>
                </MenuButton>
                <MenuList overflowY="auto" maxH={'300px'} width={'300px'}>
                  {onboardingList.map((questionnaire: any, i) => (
                    <MenuItem
                      onClick={() => setSelectedOnboardingId(questionnaire.id)}
                      key={'questionnaire-' + i + '-' + questionnaire.id}
                    >
                      {questionnaire.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>

            <Text
              marginBottom={'5px'}
              fontSize={'14px'}
              color="#aaa"
              fontWeight="14px"
            >
              {t('adminOnboarding.roles')}
            </Text>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                bg="#3FB2D2"
                color="#fff"
                rounded={'100px'}
                lineHeight="42px"
                minWidth={'112px'}
                width={'250px'}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                display="block"
              >
                {!!selectedRoleIds.length
                  ? (company?.roles ?? [])
                      .filter((r: any) => selectedRoleIds.includes(r.id))
                      .map((r: any) => r.name)
                      .join(', ')
                  : t('adminOnboarding.roles')}
                {t('adminOnboarding.roles')}
              </MenuButton>
              <MenuList overflowY="auto" maxH={'300px'}>
                <Text px={3} fontWeight="bold" mb={2}>
                  {t('adminOnboarding.roles')}
                </Text>
                {(company.roles ?? []).map((r: any, i: number) => (
                  <MenuItem key={'role-' + i + '-' + r.id}>
                    <Checkbox
                      mr={2}
                      colorScheme="white"
                      borderColor="#3FB2D2"
                      iconColor="#3FB2D2"
                      iconSize={'17px'}
                      size="lg"
                      id={r.id}
                      name={r.id}
                      isChecked={selectedRoleIds.includes(r.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRoleIds([...selectedRoleIds, r.id]);
                        } else {
                          setSelectedRoleIds(
                            selectedRoleIds.filter((id) => id !== r.id),
                          );
                        }
                      }}
                    >
                      {r.name}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </ModalBody>
          <ModalFooter width={'full'}>
            <PrimaryButton
              styles={{ minW: 'full' }}
              onClick={() => {
                setCompanyOnboardingQuestionnaire();
                onClose();
              }}
            >
              {t('adminOnboarding.save')}
            </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OnboardingSettings;
